body {
  margin-top: 10px;
  background-color: #e9ebee;
}

svg.board {
  width: 100%;
}

div.board {
  margin-bottom: 1em;
}

svg text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}

.tile, .tile * {
  transition: all 0.3s ease;
}

.tile rect {
  fill: #ddd;
}

rect.shadow {
  fill: #aaa;
}

.tile:hover rect {
  fill: #ccc;
}


.tile:active {
  -webkit-transform: translate(0, 0.5em);
          transform: translate(0, 0.5em);
  transition: all 0.2s cubic-bezier(.25,.8,.25,1);
}

.tile:active rect {
  fill: #ccc;
}

.overlay {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f7f7f9;
  max-height: 50%;
  height: 160px;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

